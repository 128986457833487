<template>
  <section class="benner">
    <h1>{{ $t('Head.Lecturer') }}</h1>
    <!-- 讲师专区 -->
    <p>{{ $t('teacher.banner_tip') }}</p>
    <!-- 企业内训讲师资源，可以快速了解企业师资力量 -->
  </section>

  <section class="filter-wrap" id="pageTop">
    <div class="content">
      <!-- 分类 -->
      <TreeFilter :name="$t('category')" :dataSource="folderData" @onChange="folderChange">
        <template v-slot:right>
          <div class="search">
            <div class="search-icon" @click="search">
              <SearchOutlined style="color: #656565" />
            </div>
            <a-input
              class="search-input"
              v-model:value.trim="searchVal"
              :placeholder="$t('please_enter_keywords')"
              allow-clear
              @pressEnter="search"
            />
            <!-- 请输入关键字 -->
          </div>
        </template>
      </TreeFilter>
      <!-- 格式 -->
      <ListFilter :name="$t('course.format')" :dataSource="fileType" @onChange="typeChange">
        <template v-slot:right>
          <div class="order-box">
            <a
              href="javascript:;"
              :class="{ active: orderActive === 1 }"
              @click="orderChange(1)"
            >
              <ClockCircleFilled />{{ $t('CM_Newest') }}
              <!-- 最新 -->
            </a>
            <a
              href="javascript:;"
              :class="{ active: orderActive === 2 }"
              @click="orderChange(2)"
            >
              <FireFilled />{{ $t('hottest') }}
              <!-- 最热 -->
            </a>
          </div>
        </template>
      </ListFilter>
    </div>
  </section>

  <section class="course">
    <div class="content">
      <a-spin :spinning="courseLoading">
        <template v-if="courseData.length">
          <div class="cards clearfix">
            <div
              class="item fl"
              v-for="item in courseData"
              :key="item.courseId"
            >
              <CourseCard :dataSource="item" sourceType="teacher" />
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :defaultPageSize="20"
              v-model:current="currentPage"
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a-button v-if="type === 'prev'">{{ $t('pre') }}</a-button>
                <a-button v-else-if="type === 'next'">{{ $t('next') }}</a-button>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty v-else style="padding: 50px 0" />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import TreeFilter from "@/components/filter/TreeFilter.vue";
import ListFilter from "@/components/filter/ListFilter.vue";
import CourseCard from "@/components/course/CourseCard.vue";
import { fileType } from "@/utils/business";
import { courseFolder } from "@/api/course";
import { onlineCourse } from "@/api/teacher";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    TreeFilter,
    ListFilter,
    CourseCard,
    renderVNode,
  },
  setup() {
    // 目录
    let folderId = 0;
    let folderData = ref([]);
    courseFolder().then((res) => {
      if (res.ret === 0) {
        folderData.value = res.data || [];
      }
    });
    const folderChange = ({ activeId, crumbs }) => {
      folderId = activeId;
      getCourseList();
    };

    // 格式
    let typesActive = [0];
    const typeChange = (arr) => {
      typesActive = arr;
      getCourseList();
    };

    // 排序
    const orderActive = ref(1),
      orderChange = (type) => {
        orderActive.value = type;
        getCourseList();
      };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getCourseList();
      };

    // 课程
    const courseData = ref([]),
      courseLoading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      getCourseList = (scroll = false) => {
        courseLoading.value = true;
        onlineCourse({
          page: currentPage.value,
          pageSize: 20,
          keyword: searchVal.value,
          folderId: folderId,
          order: orderActive.value,
          types: typesActive[0] === 0 ? [] : typesActive,
        }).then((res) => {
          courseLoading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            courseData.value = res.data.list || [];
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getCourseList(true);
      };
    getCourseList();

    return {
      folderData,
      folderChange,
      fileType,
      typeChange,
      orderActive,
      orderChange,
      searchVal,
      search,
      getCourseList,
      courseData,
      courseLoading,
      currentPage,
      pageTotal,
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
.benner {
  height: 324px;
  background-image: url("../../assets/image/teacher_calendar_banner.png");
  background-position: center;
  background-repeat: repeat-y;
  .mixinFlex(center; center; column);
  h1 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 2px;
  }
  p {
    color: #fff;
    font-size: 26px;
    margin-bottom: 60px;
  }
}

.filter-wrap {
  background-color: #fff;
  margin-top: -60px;
  .content {
    .mixinWrap();
    .order-box {
      height: 34px;
      .mixinFlex(flex-end; center);
      a {
        font-size: 14px;
        color: #666;
        margin-left: 26px;
        span {
          padding-right: 4px;
        }
        &:hover,
        &.active {
          color: @color-theme;
        }
      }
    }
    .search {
      .mixinFlex(space-between; center);
      width: 220px;
      height: 32px;
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      border: 1px solid #D9D9D9;
      float: right;
      &-icon {
        width: 34px;
        height: 14px;
        .mixinFlex(center; center);
        border-right: 1px solid #bbbbbb;
        cursor: pointer;
      }
      ::v-deep(.ant-input-affix-wrapper-focused) {
        border-color: none;
        box-shadow: none;
      }
      &-input {
        border: none;
        width: calc(100% - 34px);
        height: 100%;
        background-color: #fff;
        padding: 0 8px;
        font-size: 14px;
        ::v-deep(.ant-input) {
          background-color: #fff;
        }
      }
    }
  }
}

.course {
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      .item {
        margin-right: 24px;
        margin-bottom: 24px;
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>